import React from 'react';
import Hero from '@components/Hero';
import Streamfields from '@streamfields/Streamfields';
import type { Streamfield, WagtailImage } from '@/types';
import { basePageWrap, BasePageProps } from '../BasePage';

type Props = {
  title: string;
  banner_image: WagtailImage | null;
  content: Streamfield[];
} & BasePageProps;

function HomePage(props: Props) {
  const { title, content, banner_image: bannerImage } = props;

  return (
    <div className='home-page'>
      <Hero title={title} image={bannerImage} />
      {content ? <Streamfields fields={content} /> : null}
    </div>
  );
}

export default basePageWrap<Props>(HomePage);
