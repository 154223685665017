import React from 'react';

import { WagtailImage } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@utils/url';

type Props = {
  title: string;
  image?: WagtailImage | null;
};

function Hero(props: Props) {
  const { title, image } = props;

  return (
    <div role='banner' className='hero'>
      {image ? (
        <div className='hero__image'>
          <Image
            src={addBaseUrl(image.meta.download_url)}
            alt={image.title}
            layout='responsive'
            width={1200}
            height={500}
            objectFit='cover'
            priority
          />
        </div>
      ) : null}
      <h1 className='hero__title'>{title}</h1>
    </div>
  );
}

export default Hero;
